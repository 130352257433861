<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_pannel">

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>Recruit</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        용한건축이 일을 임하는 자세는 이해관계자들의 복잡한 니즈를 명확히 이해하고 협력하여 용하게 헤쳐나가는 것입니다. 용한건축은 앞으로 "함께-오래-잘하는 작업 환경"을 만들고자 합니다. 예비신입사원과 소통이 필요하며 방학기간마다 실습기간을 거쳐 정식 멤버로 채용하고자 합니다. 개인과 회사의 비전이 일치할 때 시너지가 난다고 생각합니다.
                                    </p>

                                    <p>
                                        관심이 있으신 분은 yonghanoffice@naver.com 으로 다음의 서류를 발송하여 주시면 됩니다. (재학증명서 or 졸업증명서, 성적증명서, 이력서 및 자기소개서) 메일은 수시로 받고 방학 전에 면접을 통하여 실습을 확정하도록 하겠습니다.
                                    </p>

                                    <p>
                                        -2025.01-
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>Overview</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        용한건축은 기획부터 준공까지 전과정을 아우르며, 협업자와 소통을 중시한다. 준공하면 관계자는 떠나고 도시에 건물만 남는다. 우리가 설계한 건물이 도시에 미관적으로나, 쓰임적으로나 괜찮은 건물이 되기를 바라기에 주어진 상황에서 건축사의 소임을 다한다.
                                    </p>

                                    <p>
                                        용한건축은 설계 과정을 중시한다. 구상을 구체화시키는 과정에서 그리기 과정을 반복한다. 우리가 그린 계획안을 관철시킬 수 있는 건축가로 자라나기 위해 발돋움하고 있다.
                                    </p>

                                    <p>
                                        Yonghan Architecture implements the whole process from planning to completion, and values communication with collaborators. When an architecture project is completed, stakeholders leave behind the building that remains ever after in the city. We hope that the buildings we design have meanings for the city both aesthetically and practically. Therefore, we fulfill our mission as architects under the given circumstances.
                                    </p>

                                    <p>
                                        Yonghan Architecture puts the design process on the top priority. we make and refine the design process in the process of materializing the concept. We are stepping up to grow into architects who can implement the plans we make.
                                    </p>

                                    <p>
                                        -2023.06-
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_cont">
                                    <p>
                                        용한건축은 건축주의 구상을 실현하기 위해 시대를 관찰하며 세상의 이치를 탐구한다. 우리가 설계한 건물이 도시에서 잘 살아남을 수 있도록, 도시를 살리는 건물이 되도록, 건축사의 역량을 다한다. 우리는 건설사업을 이해하려고 노력한다. 우리가 진행하는 사업이 안전하게 나아가길 바라기에, 우리는 시작부터 설계각론과 법, 건설 기술에 기초하여 설계/감리한다.
                                    </p>

                                    <p>
                                        Yonghan Architects observes the age and researches the principles of the world to satisfy our client's desires. We do our best to make the building we designed can survive well in the city and become a building that saves the town. We try to understand the process of construction. To ensure that our project is conducted smoothly, we design/construction manage based on design theory, law, and construction technology from the beginning.
                                    </p>

                                    <p>
                                        -2022.06-
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_cont">
                                    <p>
                                        용한건축은 건축물의 용도, 건축물의 용적, 건축물의 용모. 건축의 기본을 게을리하지 않는다. 도시에서 건물을 짓는 일이란 사업성 담보를 기반으로, 도시의 공공성 확보 및 지역성까지 고려되어야 한다. 용한건축은 개별 건축을 넘어 도시로 생각을 확장할 수 있으며, 계속해서 가치 있고 아름다운 공간을 만들기 위해 더 노력하고자 한다.
                                    </p>

                                    <p>
                                        용한건축(Yonghan Architecture)은 2021년 6월 변희영에 의해 설립되었다.
                                    </p>

                                    <p>
                                        Yonghan Architecture focuses on the fundamental architectural roles such as the building's usage, F.A.R., and appearance. Making a building in a city is based on not only the guarantee of business feasibility but also be considered the city's publicness and locality. Yonghan Architecture tries to design a building which can expand its existence to the city beyond individual architecture and valuable and beautiful spaces.
                                    </p>

                                    <p>
                                        Yonghan Architecture was founded in June 2021 by Byeon Hui-young.
                                    </p>

                                    <p>
                                        -2021.06-
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong></strong>
                                </div>

                                <!--
                <div class="pu_writing_img"
                     style="margin-top:10px; margin-bottom:50px;">

                    <img class="pu_width_250" src="/common/img/item/person/person_2.jpg" />
                </div>
                -->
                                <!--
                <div class="pu_writing_cont">
                    <p class="pu_writing_cont_title">
                        <strong>CEO / Master Architect&nbsp;&nbsp;&nbsp;<span style="font-size:14px;">변&nbsp;&nbsp;희&nbsp;&nbsp;영</span></strong>
                    </p>

                    <p>
                        <span class="pu_display_block pu_padding_left_10">2024.01 - 출판사 이름변경(도시건축연구소)</span>
                        <span class="pu_display_block pu_padding_left_10">2021.05 - 건축사사무소 설립</span>
                        <span class="pu_display_block pu_padding_left_10">2020.05 - 출판사 설립</span>
                    </p>

                    <p>
                        <strong>Academy</strong>
                        <span class="pu_display_block pu_padding_left_10">2024.03 ~ 서울시립대 일반대학원 도시공학과 박사과정</span>
                        <span class="pu_display_block pu_padding_left_10">2018.02 서울시립대 도시과학대학원 건축공학과 석사졸업</span>
                        <span class="pu_display_block pu_padding_left_10">2009.02 목원대학교 건축학부 학사졸업</span>
                        <span class="pu_display_block pu_padding_left_10">2004.02 신철원 고등학교 졸업</span>
                    </p>

                    <p>
                        <strong>Previous work</strong>
                        <span class="pu_display_block pu_padding_left_10">2009년 세운재정비촉진지구 국제지명현상설계 공모로 건축설계 실무를 시작하여 2021년까지 대한민국에서 건축설계 실무경력을 쌓았다. 도시재생, 일반건축물, 공공건축물, 공동주택 등의 건축유형을 다루었으며, 현상설계, 인허가, 기획용역 등의 업무를 수행하였다.</span>
                    </p>
                </div>
                -->
                                <!--<div class="pu_writing_cont">
                    <p class="pu_writing_cont_title">
                        <strong>변 희 영 건축사</strong>
                    </p>

                    <p>
                        학력
                        <span class="pu_display_block pu_padding_left_10">2004.02 신철원 고등학교 졸업</span>
                        <span class="pu_display_block pu_padding_left_10">2009.02 목원대학교 건축학부 졸업</span>
                        <span class="pu_display_block pu_padding_left_10">2018.02 서울시립대 도시과학대학원 건축공학과 졸업</span>
                    </p>

                    <p>
                        실무
                        <span class="pu_display_block pu_padding_left_10">2009.03 ~ 2018.03 동우건축</span>
                        <span class="pu_display_block pu_padding_left_10">2018.04 ~ 2019.08 푸른건축</span>
                        <span class="pu_display_block pu_padding_left_10">2019.05 ~ 2021.05 광명ENG건축</span>
                        <span class="pu_display_block pu_padding_left_10">2021.06 ~ 용한건축</span>
                    </p>

                    <p>
                        활동
                        <span class="pu_display_block pu_padding_left_10">
                            2021.06 ~ 2023.05 시흥시 도시공원위원회 심의위원 위촉
                        </span>
                        <span class="pu_display_block pu_padding_left_10">
                            2021.08 ~ 대한건축학회 입회
                        </span>
                        <span class="pu_display_block pu_padding_left_10">
                            2022.01 ~ 시흥지역건축사회 입회
                        </span>
                        <span class="pu_display_block pu_padding_left_10">
                            2023.06 ~ 2025.05 시흥시 도시공원위원회 심의위원 연임
                        </span>
                    </p>
                </div>-->
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>회사 위치</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        주소: 경기도 시흥시 수풀안길 14-2, 1층 (목감역 2024년 개통 예정)
                                    </p>

                                    <p>
                                        yonghanoffice@naver.com
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- //contents -->

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import

    // export
    export default {

        data() {
            return {

                gubun_page: "LIST"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

            }
        }

        , methods: {

            // 진입점
            init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var data = {};
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/about/list", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.list_1 = data.LIST_1; // 리스트

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {



                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>